import React, { useEffect } from "react";
import { Link } from "react-router-dom"; // Import this only if you're using React Router
import "./navbar.css"; // Make sure to create a corresponding CSS file
import Cart from "./cart";

const NavigationBar = ({
  cart,
  cartItemCount,
  handleRemoveFromCart,
  isCartVisible,
  toggleCart,
}) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the clicked element or any of its ancestors have a "cart" class
      let target = event.target;
      let isInsideCart = false;

      while (target) {
        if (target.classList.contains("cart")) {
          isInsideCart = true;
          break;
        }
        target = target.parentElement;
      }

      // If not inside the cart, close it
      if (!isInsideCart && isCartVisible) {
        toggleCart(); // This should only toggle if necessary, or replace with a direct 'hide' function if available
      }
    };

    window.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isCartVisible, toggleCart]);

  const cartStyle = {
    right: isCartVisible ? "0" : "-100%", // Slide in when visible, slide out when hidden
  };

  /*<img
              className="weblogo"
              src="/anatolinkwebsitelogo.png"
              alt=""
            ></img> */

  return (
    <nav className="navigation-bar">
      <div
        className={`overlay ${isCartVisible ? "showOverlay" : ""}`}
        onClick={toggleCart}
      ></div>
      <ul className="nav-list">
        <li className="nav-item">
          <Link to="/">
            <img className="weblogo" src="/topleft.png" alt=""></img>
          </Link>
        </li>
      </ul>
      <div className="menu-icon">&#9776;</div>
    </nav>
  );
};

export default NavigationBar;
